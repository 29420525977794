(function ($) {

  $(document).ready(function () {

    // **********************

    $('.passage a, .paragraph-publication a, a[href*="gaia-group.com"], a[href*="servier.com"], a[href*="google.de"]').not('.btn').attr('target', '_blank');

    // ********** jQuery plugins ************ 

    $('.passage span').mdSpanStyles();
    
    $('body').scrollToAnchors();

    $('#bottom').bottomize();
    
    $('.glyphicons').glyphClone();

    $('.jumbotron a[title^=button]').mdLinkToButton();
    
    $('.spots a[title^=button]').mdLinkToButton();
    
    $('.stripe a[title^=button]').mdLinkToButton();
    
    $('.jumbotron img').mdImageSize();
    
    $('body').bobButton();

    $(window).scrollTopArrow();

    $('body').fadePage();

    $('body').formAccess();

    $('.bob-frame-show').bobframe();

    // **********************

  });

}(jQuery));
