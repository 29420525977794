import _ from 'lodash';
import '../styles/main.scss';

import 'jquery';

// scripts that are copied from the libs folder.
// This is done via webpack copy plugin in webpack.common.js
// the files are copied into the "fromLibs" folder.
import '../scripts/fromLibs/jquery.scrollTo.min.js';

//Bootstrap
// import 'bootstrap';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

// Bob scripts form Libs
import '../scripts/fromLibs/spinner.js';
import '../scripts/fromLibs/bobframe.js';
import '../scripts/fromLibs/bottomize.js';
import '../scripts/fromLibs/glyphicons.js';
import '../scripts/fromLibs/md_buttons.js';
import '../scripts/fromLibs/md_images.js';
import '../scripts/fromLibs/md_spanStyles.js';
import '../scripts/fromLibs/bobbutton.js';
import '../scripts/fromLibs/scrollTop_arrow.js';
import '../scripts/fromLibs/scrollTo_anchors.js';
import '../scripts/fromLibs/fadePage.js';

// Project specific scripts
// import '../scripts/bobform_contact.js';
// import '../scripts/accessform_wp.js';
import '../scripts/form_access.js';
import '../scripts/main.js';