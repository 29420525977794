(function ($) {

    $.fn.formAccess = function () {

        var element = this; // body

        var msg = null;

        var successTimeout = null;

        var bobMsgHide = function (fade = true) {
            // if(!duration) {
            //   duration = 0;
            // }
            let duration = (fade) ? 200 : 0;
            $('.bob-msg').fadeOut(duration, function () {
                $('.bob-msg .msg-content').hide();
            });
            msg = null;
        };

        var bobMsgSuccess = function () {
            msg = 'success';
            bobMsgShow(msg);
            successTimeout = window.setTimeout(bobMsgHide, 3000, 200);

            // bobHide();
            var event = $.Event('dohide.bobframe');
            $('.bob-frame').trigger(event);
        };

        var bobMsgWarning = function () {
            msg = 'warning';
            bobMsgShow(msg);
        };

        var bobMsgError = function () {
            msg = 'error';
            bobMsgShow(msg);
        };

        var bobMsgShow = function (msg) {
            clearTimeout(successTimeout);
            $('.bob-msg .msg-' + msg).show();
            $('.bob-msg').fadeIn(200);
        }

        let btnStateSwitch = function () {

            if ($('.bob-btn-custom').hasClass('inactive')) {
                $('.bob-btn-custom').removeClass('inactive');
            } else {
                $('.bob-btn-custom').addClass('inactive');
            }
        }

        var sendData = function (e) {

            e.preventDefault();

            $('#submit', '.bob-frame').prop('disabled', true);
            $('.form-group input', '.bob-frame').removeClass('is-invalid');

            bobMsgHide(false);

            var data = {};
            var notValid = false;

            if ($('#checkOption').is(':checked')) {

                data.description = $('#checkOption').siblings('label').text() + "\n\n" + data.description;
                $('#checkOption').removeClass('is-invalid');

            } else {
                $('#checkOption').addClass('is-invalid');
                $('#checkOption').parent('label').addClass('is-invalid');

                notValid = true;
            }


            if (notValid) {
                // enable button again
                $('#submit', '.bob-frame').prop('disabled', false);
                bobMsgWarning();
                return;
            }

            $('document').spinner('show');

            var voucher = "voucher";
            var endpoint = "/api";

            // console.log(data);

            $.ajax({
                url: endpoint,
                type: 'GET',
                data: {
                    voucher: voucher
                },
                dataType: "json",
                success: function (response) {
                    // console.log(response);
                    // google tag manager

                    if (window.dataLayer !== undefined) {
                        window.dataLayer.push({
                          "event": "conversionAccess"
                        });
                      }

                    // base64 encode variable
                    var params = btoa(JSON.stringify({
                        "confirm::receiving::questionnaire": true
                    }));
                    // compose redirect location
                    var loc = response.endpoint + "de/registration/" + response.voucher;
                    loc = loc + "?v=" + params;

                    // console.log(atob(params));
                    // redirect to registration
                    // window.location.href = loc;

                    // open bob-frame with content from bob-content-confirm
                    $('.bob-frame-show').trigger('click');

                    // attach the voucherized href to the button within the recently opened bob-frame
                    $('#bob-frame-confirm-btn').prop('href', loc);

                    $('document').spinner('hide');

                },


                error: function (err) {
                    // console.log(err);
                    // var errmsg = $('form.form-voucher').data('errmsg');
                    bobMsgError();
                    // enable button again
                    $('#btn-submit', 'form.form-voucher').prop('disabled', false);
                    $('document').spinner('hide');
                }
            });

        };

        $('.bob-frame').on('hide.bobframe', function () {
            // if bobframe is closed, clear up some things
            // 1. remaining msg boxes
            if (msg === 'error' || msg === 'warning') {
                bobMsgHide();
            }
            // 2. remove red from inputs
            // $('.form-group input').removeClass('is-invalid');
        });

        $(document).off('.access');

        $(document).on('submit.access', 'form.form-access', function (e) {
            sendData(e);
        });

        $(document).on('click.access', '.bob-msg', function () {
            bobMsgHide();
        });

        $(document).on('click.access', '.custom-control-label', function () {
            btnStateSwitch();
        });

        $(document).on('click.access', '.bob-frame .modal-close', function () {
            bobMsgHide();
            var event = $.Event('dohide.bobframe');
            $('.bob-frame').trigger(event);
        });

        return this;

    };

}(jQuery));
