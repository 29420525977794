(function ($) {

  $.fn.bobframe = function () {

    var element = this;

    // use as method command
    // if (arguments.length > 0) {

    //   // method: show
    //   if (arguments[0] === 'show' && arguments[1]) {

    //     // usage: target-element.modal('show', content-container-id)

    //     $('.modal-msg, .modal-msg .msg-content').hide();

    //     var copy = $(arguments[1]).clone();
    //     copy.hide();
    //     $(element).find('.m-content').html(copy);
    //     copy.fadeIn();

    //     $(element).show();

    //     var event = jQuery.Event("show.modal");
    //     $(element).trigger(event);

    //     $('body').addClass('modal-bob-open');
    //   }

    // }
    

    var bobHide = function (e) {
      var $frame;

      
      if (e.currentTarget === window) {

        $('.bob-frame').each(function(i, m){
          if ($(m).css('display') == 'block') {
            $frame = $(m);
            // console.log($frame);

          }
        });
        
      }

      if (e.currentTarget == window.document) {
        // esc
        $('.bob-frame').each(function(i, m){
          if ($(m).css('display') == 'block') {
            $frame = $(m);

          }
        });
      } else {
        // frame or button
        var $currentTarget = $(e.currentTarget);
        if ($currentTarget.hasClass('bob-frame')) {
          $frame = $currentTarget;
        } else if (!$frame) {
          $frame = $($currentTarget.parents('.bob-frame')[0]);
        }
      }



      // console.log('bobHide', $frame, e);
      var scrollFrame = $(window).scrollTop();

      
      $frame.addClass('bob-fading-in');
      $frame.css('top', '-'+scrollFrame+'px');
      // console.log('top', '-'+scrollFrame+'px', $frame.css('top'));

      window.setTimeout(function() {

        $('body').removeClass('bob-frame-open');

        var scrollPosMain = localStorage.getItem("scrollPosMain");
        $(window).scrollTop(scrollPosMain);
      
        var event = $.Event('hide.bobframe');
        $frame.trigger(event);
      },100);

      $frame.fadeOut(400, function() {
        $frame.css('top', '0');
        $(this).find('.bob-content').html('');

          
        var event = $.Event('afterhide.bobframe');
        $frame.trigger(event);
        $frame.removeClass('bob-fading-in');
      });

      // $('body').removeClass('bob-frame-open');

    };

    var bobShow = function (e, item) {

      e.preventDefault();
      // console.log(e, item);

      // this state is used to allow the user to press the back button to close the bob-brame
      window.history.pushState('forward', null, './#bob-frame');

      $('.bob-msg').hide();

      var src = $(item).attr('href');

      if (src) {

        var target = $(item).attr('data-target');
        $('.' + target + ' .bob-content').html('');

        if (src.substr(0,1) === '#') {

          var copy = $(src).clone();
          var copyid = copy.attr('id');
          if (copyid) {
            copy.attr('id', copyid + '-mod');
          }

          // IE bug: when cloning, placeholder text is inserted as value
          // Fix: if placeholder equals value, remove value
          $('textarea', copy).each(function(i, ta) {
            if ($(ta).attr('placeholder') == $(ta).val()) {
              $(ta).val('');
            }
          }); // end IE bugfix
          copy.hide();
          $('.' + target + ' .bob-content').html(copy);
          copy.fadeIn();
        }

        // console.log('scrollTop', $(window).scrollTop());
        localStorage.setItem("scrollPosMain", $(window).scrollTop());

        $('.' + target).hide(0, function() {

          // $('.' + target).css("opacity", 0);
          // $('.' + target).css("display", "none");
          $('.' + target).addClass("bob-fading-in");

          // $('.' + target).show();

          $('.' + target).fadeIn(200, function() {
            $(window).scrollTop(0);
            $('.' + target).removeClass("bob-fading-in");
            $('body').addClass('bob-frame-open');
          });


          var event = jQuery.Event("show.bobframe");
          $('.' + target).trigger(event);

          // $('body').addClass('bob-frame-open');

        });
        
      }

    };

    // use to initialise
    if (arguments.length === 0) {

      $(document).off('.bobframe');

      $(document).on('click.bobframe', '.bob-frame-show', function(e) {
        if($(this).attr('href') && !($(this).attr('href').indexOf('mailto') > -1)) {
          bobShow(e, this);
        }
      });

      $(document).on('click.bobframe', '.bob-close', function(e) {
        // bobHide(e);
        window.history.back();
      });

      $(document).on('keydown.bobframe', function (e) {
        e = e || window.event;
        if(e.keyCode === 27) {
          bobHide(e);
        }
      });

      $(document).on('dohide.bobframe', '.bob-frame', function(e) {
        bobHide(e);
      });

      // this hides the bob-frame if the browsers-back button is pressed!
      $(window).on('popstate.bobframe', function (e) {
        bobHide(e);
        // window.history.pushState('forward', null, './#bob-frame');

    });
      // $('.modal').on('scroll.bobframe', function () {
      //   var mtop = $('.m-window', this).offset().top;
      //   var ctop = (window.pageYOffset - mtop);
      //   if (ctop > 0) {
      //     $('.bob-close', this).css('top', ctop + 'px');
      //   } else {
      //     $('.bob-close', this).css('top', '0px');
      //   }
      // });

    }

    return this;

  };

}(jQuery));
